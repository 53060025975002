.footer {
    width:100%;
    position: fixed;
    height: 60px;
    line-height: 60px;
    bottom:0;
    background: #5eaeff;
    text-align: center;
    color: white;
}

#content {
    padding-bottom: 60px;
}